import styled from "styled-components"

import { TextButton } from "src/ui/Button/TextButton"
import { spacing } from "src/ui/spacing"

export function ParadiseButtonsPager({
  offset,
  limit,
  hasNextPage,
  setOffset,
}: {
  offset: number
  limit: number
  hasNextPage?: boolean
  setOffset: (offset: number) => void
}) {
  const currentPage = offset / limit
  return (
    <PaginationWrapper>
      {(currentPage > 0 || hasNextPage) && (
        <>
          <TextButton
            onClick={() => setOffset(offset - limit)}
            disabled={currentPage === 0}
          >
            Previous
          </TextButton>
          <TextButton
            onClick={() => setOffset(offset + limit)}
            disabled={!hasNextPage}
          >
            Next
          </TextButton>
        </>
      )}
    </PaginationWrapper>
  )
}

const PaginationWrapper = styled.div`
  margin-block-start: ${spacing.L};
  display: flex;
  align-items: center;
  gap: ${spacing.L};
  justify-content: flex-end;
`
